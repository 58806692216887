import './Header.css';
function Header({ onLinkClick }) {
    const handleLinkClick = (link) => {
        // Call the onLinkClick function with the clicked link
        onLinkClick(link);
    };
  return (
    <div id="header">

            <div className="menu">
              <nav>
                  <ul>
                      <li><a href="index.html">
                          <img src="images/logos/main-small.webp" alt="BeeCare Life home"/>
                      </a>
                      </li>
                      <li> <button onClick={() => handleLinkClick('purpose')}>Purpose</button></li>
                      <li> <button onClick={() => handleLinkClick('mission')}>Mission</button></li>
                      <li> <button onClick={() => handleLinkClick('opportunity')}>Opportunity</button></li>
                      <li> <button onClick={() => handleLinkClick('services')}>Services</button></li>
                      <li> <button onClick={() => handleLinkClick('updates')}>Updates</button></li>
                      <li> <button onClick={() => handleLinkClick('contact')}>Contact</button></li>
                  </ul>
              </nav>
            </div>
          <div className="top-banner-image">
            <div className="text-overlay">
              <h1>BEE Care Life - Be Environment Enriching!</h1>
            </div>
          </div>

    </div>
  );
}

export default Header;
