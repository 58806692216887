import './Contact.css';

function Contact() {
  return (
    <div >
        <section id="contact">
          <h2>Contact</h2>
          <p>Simply send <a href="mailto:beecare.life@gmail.com">us email.</a></p>
        </section>
    </div>
  );
}

export default Contact;
