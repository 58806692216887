import './Services.css';

function Services() {
  return (
    <div >
                <section id="services">
                  <h2>Services</h2>
                  <h3>Coming soon...</h3>
                  <div className="grid">
                    <div className="grid-item">
                      <img src="images/Regenerative-farming-practices.webp" alt="Regenerative farming"/>
                      <h2>Regenerative farming practices</h2>
                      <p></p>
                    </div>
                    <div className="grid-item">
                      <img src="images/Syntropic-agriculture-consulting.webp" alt="Syntropic Consulting"/>
                      <h2>Farm design, water & soil management</h2>
                      <p></p>
                    </div>

                    <div className="grid-item">
                      <img src="images/Farm-land-design.webp" alt="Farm design"/>
                      <h2>Technology and value added services</h2>
                      <p></p>
                    </div>
                    <div className="grid-item">
                      <img src="images/organic-farming.webp" alt="Organic farming"/>
                      <h2>Organic farming</h2>
                      <p></p>
                    </div>
                    <div className="grid-item">
                      <img src="images/composting.webp" alt="Composting"/>
                      <h2>Composting</h2>
                      <p></p>
                    </div>
                    <div className="grid-item">
                      <img src="images/education.webp" alt="Community outreach and education"/>
                      <h2>Community outreach and education</h2>
                      <p></p>
                    </div>
                  </div>
                </section>
    </div>
  );
}

export default Services;
