import './App.css';
import Header from './Header';
import PurposeMission from './PurposeMission';
import Services from './Services';
import Contact from './Contact';
import Footer from './Footer';
import Updates from './Updates';
import React, { useState, useEffect } from 'react';

function App() {
    const [showUpdatesModule, setShowUpdatesModule] = useState(false);
    const [scrollToSection, setScrollToSection] = useState('');
    const handleLinkClick = (link) => {
        if(link === 'updates'){
            setShowUpdatesModule(true);
        } else {
            setShowUpdatesModule(false);
        }
        setScrollToSection(link)
      };
    useEffect(() => {
        if (scrollToSection){
          const section = document.getElementById(scrollToSection);
          section.scrollIntoView({ behavior: 'smooth' });
        }
    }, [scrollToSection]);

  return (
    <div>
    <Header onLinkClick={handleLinkClick} />
    <main>
      <div className="body-container">
      {showUpdatesModule && <Updates/>}
      {!showUpdatesModule &&  <PurposeMission/>}
      {!showUpdatesModule && <Services />}
      {!showUpdatesModule && <Contact/> }
      </div>
    </main>
    <Footer />
    </div>
  );
}

export default App;
