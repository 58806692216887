import './Updates.css';

function Updates() {
  return (
    <div>
    <section id="updates">
        <h2>Q2 2023 - The Genesis</h2>
        <p>We are thrilled to provide a few updates and share pictures of BeeCare Life farm, offering a glimpse into the early stages of our journey towards syntropic farming.<br/><br/>
        We are currently faced with a couple of challenges and have formulated some initial hypotheses to address them. <br/><br/>
           1.	<b>Improving Clay Soil:</b> The soil quality on our farm is extremely poor due to a high clay content. To rectify this, plan involves incorporating 100kg of gypsum per acre to break down the clay and adding approximately 1 foot of soil. Additionally, we will apply cow dung compost on top to enhance its fertility. <br/><br/>
           2.	<b>Managing High Groundwater hardness level:</b> The groundwater level around the area is relatively high, approximately 20 feet deep, but the water is quite hard. To tackle this issue, we intend to establish a rainwater harvesting borewell. However, we are still exploring alternatives to obtain better quality water from alternative sources.<br/><br/>
           3.	<b>Optimal Row Layout:</b> Given that our farm is located at approximately 27 degrees north and 77 degrees east, we have decided to arrange  rows in a north to south direction to maximize sun exposure and optimize plant growth.<br/><br/>
           4.	<b>Dealing with Potential Flooding:</b> Since farm is situated close to a river channel, heavy rainfall can result in flooding for few days. To mitigate this risk, we am planning to create a slope using a land leveller and incorporate a fanya chini trench towards the end of the slope/boundary area.<br/><br/>
        </p>
        <div className="intro-grid">
            <div className="intro-grid-item">
              <img src="images/Layout.webp" alt="BeeCare Life layout. You are nature! Invest in yourself: take care today, thrive tomorrow."/>
            </div>
            <div className="intro-grid-item">
               <img src="images/Land1.webp" alt="BeeCare Life land. You are nature! Invest in yourself: take care today, thrive tomorrow."/>
                <img src="images/Land2.webp" alt="BeeCare Life land. You are nature! Invest in yourself: take care today, thrive tomorrow."/>

            </div>
          </div>
    </section>
    </div>
  );
}

export default Updates;
