import './PurposeMission.css';

function PurposeMission() {
  return (
  <div>
        <section id="purpose">
          <div className="intro-grid">
            <div className="intro-grid-item">
              <h2>Purpose</h2>
              <p>Create a sustainable and profitable agriculture system that supports individuals, farmers and communities while protecting the environment. The ultimate goal is to establish sustainable agriculture practices that not only feed the world but also protect the planet for future generations.</p>
            </div>
            <div className="intro-grid-item">
              <img src="images/logos/main-small.webp" alt="You are nature! Invest in yourself: take care today, thrive tomorrow."/>
              <p></p>
            </div>
          </div>
        </section>

        <section id="mission">
          <h2>Our Mission</h2>
          <p>Developing an agricultural model that strike a balance between economic viability, commercially viable and environmental responsibility, creating a long-term, sustainable solution for food production.</p>
        </section>
        <section id="opportunity">
          <h2>Opportunity</h2>
          <p>The agriculture sector is a significant contributor to many country's GDP but mostly modern agriculture practices are followed like tilling, heavy use of chemicals, pesticide, least/no soil management, no water management etc.
          </p>
          <ul>
            <b>1. Soil re-generation:</b>  Soil agriculture, deforestation, and other factors have degraded and eroded topsoil at alarming rates. Globally, 52% of agricultural land is already degraded. The planet is in crisis. If current rates of soil degradation continue, this would be the end of life as we know it. Know more -
            <a href="https://consciousplanet.org/soil-policy" target="_blank" rel="noopener noreferrer">
              Save-soil <img src="images/icons/icons8-external-link-50.webp" alt="Save Soil" width="16" height="16"/>
            </a>
          </ul>
          <ul><b>2. Government Support:</b> Governments are promoting sustainable agriculture practices, including agroforestry, through various initiatives and policies. This creates a market opportunity for businesses that provide services and products related to syntropic farming, such as consultancy services, training, and equipment.
          </ul>
          <ul><b>3. Climate Change Mitigation:</b> Modern agriculture practices are the biggest GHG(Methane, Nitrous oxide) emitter. Syntropic farming practices can help mitigate climate change by sequestering carbon in the soil and reducing greenhouse gas emissions. As the need for climate change mitigation becomes more urgent, there is a growing market opportunity for businesses involved in sustainable agriculture practices such as syntropic farming.
          </ul>
          <ul><b>4. Supply Chain Transparency:</b> Consumers are increasingly interested in knowing where their food comes from and how it is produced. Syntropic farming provides an opportunity for businesses to offer transparent and traceable supply chains, which can appeal to consumers who prioritize sustainability and ethical production practices.
          </ul>
          <ul><b>5. Health and Wellness:</b> Syntropic farming produces food that is high in nutrients and free from harmful chemicals. As consumers become more health-conscious and concerned about the impact of their food choices on the environment, there is a growing market opportunity for businesses that offer healthy and sustainably produced food.
          </ul>
          <ul><b>6. Circular Economy:</b> Syntropic farming can contribute to the circular economy by reducing waste and creating closed-loop systems. For example, food waste can be used to produce compost, which can be used to improve soil health and increase crop yields. This creates a market opportunity for businesses involved in waste management, composting, and circular economy initiatives.
          </ul>
          <ul><b>7. Resilience:</b> Syntropic farming can increase the resilience of agricultural systems to climate change, extreme weather events, and other environmental challenges. This creates a market opportunity for businesses involved in climate resilience and adaptation, including companies that offer services and products related to climate-smart agriculture, water management, and disaster risk reduction.
          </ul>
          <ul><b>8. Technology:</b> There is very limited tech adoption in Syntropic practices but has huge potential in end-2-end Syntropic cycle management like diversify crop portfolios by providing tools for crop rotation planning, mixed cropping, soil analysis, seed selection, best crops for each stage of succession, predictive analytics can be used to anticipate extreme weather events etc.
          </ul>
        </section>
    </div>
  );
}


export default PurposeMission;
