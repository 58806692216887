import './Footer.css';

function Footer() {
  return (
    <div>
        <footer id="contact">
            <div  className="footer">
                <p>2023 BEE Care Life. All rights reserved.</p>
            </div>
        </footer>
    </div>
  );
}

export default Footer;
